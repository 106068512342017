body {
    margin: 0;
    font-family: Arial, sans-serif;
}

.admin-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.admin-header {
    background-color: #333;
    color: white;
    padding: 1rem;
}

.admin-header h1 {
    margin: 0;
}

.admin-header nav {
    margin-top: 1rem;
}

.admin-header nav a {
    color: white;
    margin-right: 1rem;
    text-decoration: none;
}

.main-content {
    display: flex;
    flex: 1;
}

.admin-sidebar {
    width: 200px;
    background-color: #f0f0f0;
    padding: 1rem;
}

.admin-sidebar ul {
    list-style-type: none;
    padding: 0;
}

.admin-sidebar li {
    margin-bottom: 0.5rem;
}

main {
    flex: 1;
    padding: 1rem;
}

.admin-footer {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 1rem;
}

.account-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.account-page h2,
.account-page h3 {
    margin-bottom: 20px;
}

.account-page form div {
    margin-bottom: 15px;
}

.account-page label {
    display: block;
    margin-bottom: 5px;
}

.account-page input,
.account-page select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.account-page button {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.account-page button:hover {
    background-color: #0056b3;
}

.account-page .success {
    color: green;
    margin-bottom: 10px;
}

.account-page .error {
    color: red;
    margin-bottom: 10px;
}

.new-api-key {
    display: flex;
    margin-bottom: 20px;
}

.auth-form {
    max-width: 300px;
    margin: 100px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.auth-form h2 {
    margin-top: 0;
}

.auth-form .error {
    color: red;
    margin-bottom: 10px;
}

.auth-form .success {
    color: green;
    margin-bottom: 10px;
}

.auth-form form div {
    margin-bottom: 10px;
}

.auth-form label {
    display: block;
    margin-bottom: 5px;
}

.auth-form input {
    width: 100%;
    padding: 5px;
}

.auth-form button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.auth-form button:hover {
    background-color: #0056b3;
}

.auth-links {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
}

.auth-links a {
    color: #007bff;
    text-decoration: none;
    margin-bottom: 5px;
}

.auth-links a:hover {
    text-decoration: underline;
}

.new-api-key input,
.new-api-key select {
    margin-right: 10px;
}

.new-key-display {
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 4px;
}

.new-key-display code {
    display: block;
    padding: 10px;
    background-color: #e9ecef;
    border-radius: 4px;
    margin: 10px 0;
}

.api-key-list {
    list-style-type: none;
    padding: 0;
}

.api-key-list li {
    display: flex;
    flex-direction: column;
    padding: 15px;
    border: 1px solid #ddd;
    margin-bottom: 15px;
    border-radius: 4px;
}

.api-key-info {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.api-key-info input {
    flex-grow: 1;
    margin-right: 10px;
}

.api-key-info select {
    margin-right: 10px;
}

.api-key-preview {
    font-family: monospace;
    background-color: #f8f9fa;
    padding: 5px 10px;
    border-radius: 4px;
}

.api-key-usage {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.last-used {
    font-size: 0.9em;
    color: #6c757d;
}

.api-key-usage button {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.api-key-usage button:hover {
    background-color: #c82333;
}

.error-banner {
    background-color: #ffebee;
    color: #c62828;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ef9a9a;
    border-radius: 4px;
}

.dashboard {
    padding: 20px;
}

.dashboard h2 {
    margin-bottom: 20px;
}

.dashboard-stats {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.stat-card {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    padding: 20px;
    width: 30%;
    text-align: center;
}

.stat-card h3 {
    margin-bottom: 10px;
    color: #495057;
}

.stat-card p {
    font-size: 24px;
    font-weight: bold;
    color: #343a40;
}

.recent-activity {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 30px;
}

.recent-activity h3 {
    margin-bottom: 15px;
}

.recent-activity ul {
    list-style-type: none;
    padding: 0;
}

.recent-activity li {
    margin-bottom: 10px;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 4px;
}

.quick-actions {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    padding: 20px;
}

.quick-actions h3 {
    margin-bottom: 15px;
}

.quick-actions button {
    margin-right: 10px;
    padding: 10px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.quick-actions button:hover {
    background-color: #0056b3;
}

.api-key-list {
    list-style-type: none;
    padding: 0;
}

.api-key-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    margin-bottom: 10px;
}

.api-key-list li span {
    margin-right: 15px;
}

.api-key-list li button {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.api-key-list li button:hover {
    background-color: #c82333;
}

.create-key button,
.new-key-display button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.create-key button:hover,
.new-key-display button:hover {
    background-color: #0056b3;
}

.api-keys-section {
    margin-top: 30px;
}

.create-key {
    margin-bottom: 20px;
}

.create-key input {
    margin-right: 10px;
    padding: 5px;
}

.new-key-display {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 20px;
}

.new-key-display code {
    display: block;
    padding: 10px;
    background-color: #e9ecef;
    border-radius: 4px;
    margin: 10px 0;
    word-break: break-all;
}

.api-key-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.api-key-table th,
.api-key-table td {
    text-align: left;
    padding: 12px;
    border-bottom: 1px solid #dee2e6;
}

.api-key-table th {
    background-color: #f8f9fa;
    font-weight: bold;
}

.api-key-table tr:hover {
    background-color: #f8f9fa;
}

.api-key-table button {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.api-key-table button:hover {
    background-color: #c82333;
}

.create-key button,
.new-key-display button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.create-key button:hover,
.new-key-display button:hover {
    background-color: #0056b3;
}

.create-key {
    margin-bottom: 20px;
    background-color: #f8f9fa;
    padding: 15px;
    border-radius: 4px;
}

.create-key input[type='text'] {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
}

.model-selection {
    margin-bottom: 10px;
}

.model-selection label {
    display: block;
    margin-bottom: 5px;
}

.model-selection input[type='checkbox'] {
    margin-right: 5px;
}

.error {
    color: #dc3545;
    margin-bottom: 10px;
}
