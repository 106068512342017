.auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa;
}

.auth-form {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

.auth-form h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #333;
}

.auth-form input {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 1rem;
}

.auth-form button {
    width: 100%;
    padding: 0.75rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s;
}

.auth-form button:hover {
    background-color: #0056b3;
}

.auth-form .error {
    color: #dc3545;
    margin-bottom: 1rem;
}

.auth-form .links {
    text-align: center;
    margin-top: 1rem;
}

.auth-form .links a {
    color: #007bff;
    text-decoration: none;
    margin: 0 0.5rem;
}

.auth-form .links a:hover {
    text-decoration: underline;
}